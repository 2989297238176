exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacyNotice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/termsOfUse.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-answer-page-tsx": () => import("./../../../src/templates/answer-page.tsx" /* webpackChunkName: "component---src-templates-answer-page-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/article-page.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */)
}

